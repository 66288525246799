import { CircleCheck, CirclePlus, CircleX, CopyPlus, ListPlus, Loader, Upload } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { apiConstants } from '../_constants';
import { toastConstant } from '../_constants/toast.constants';
import { authHeader } from '../_helpers';
import { miscService, UploadS3UsingFetch } from '../_services/misc.service';
import { orderService } from '../_services/order.service';
import './order.css';

export const OrderAdd = () => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [order, setOrder] = useState({
    company_user_id: null,
    company_id: null,
    project_name: '',
    orderDetails: [],
  });
  const [selectedClientUser, setSelectedClientUser] = useState({});
  const [assignedUser, setAssignedUser] = useState('');

  const [isJdUploading, setIsJdUploading] = useState({
    status: false,
    index: null,
  });

  const [orderDetails, setOrderDetails] = useState([
    {
      title_id: '',
      noofjobs: '',
      description: '',
      mobilization: '',
      extra_field: [],
      jd: null,
    },
  ]);

  const handleChangeOrder = (e) => {
    const { name, value } = e.target;
    setOrder({
      ...order,
      [name]: value,
    });
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newFormRows = [...orderDetails];

    if (name == 'mobilization') {
      newFormRows[index][name] = moment(value).valueOf();
    } else {
      newFormRows[index][name] = value;
    }
    setOrderDetails(newFormRows);
  };

  // Handler to add a new row
  const handleAddRow = () => {
    setOrderDetails([
      ...orderDetails,
      {
        jobTitle: '',
        numberOfJobs: '',
        description: '',
        mobilization: '',
        extra_field: [],
        jd: null,
      },
    ]);
  };

  // Handler to remove a row
  const handleRemoveRow = (index) => {
    const newFormRows = [...orderDetails];
    newFormRows.splice(index, 1);
    setOrderDetails(newFormRows);
  };

  // Handler for uploading job description files (placeholder function)
  const handleFileUpload = async (index, event) => {
    setIsJdUploading({ status: true, index: index });
    const file = event?.target?.files[0];
    try {
      const ext = file.name.split('.').pop();
      const response = await miscService.createSignedUploadUrl({
        type: 'file',
        mime_type: file.type,
        ext,
      });

      const { signedUrl, filename } = response.data;
      const uploadResponse = await UploadS3UsingFetch(file, signedUrl);
      if (uploadResponse) {
        const newFormRows = [...orderDetails];
        newFormRows[index].jd = filename;
        setOrderDetails(newFormRows);
      } else {
        toast.error('Upload Failed! Try again!', toastConstant.TOAST_PAYLOAD);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsJdUploading({ status: false, index: null });
    }
  };

  const handleRemoveJd = (index) => {
    const newFormRows = [...orderDetails];

    newFormRows[index].jd = null;

    setOrderDetails(newFormRows);
  };

  const handleAddExtraField = (index) => {
    const newFormRows = [...orderDetails];
    newFormRows[index].extra_field = [...newFormRows[index].extra_field, { name: '' }];
    setOrderDetails(newFormRows);
  };
  const removeExtraField = (index, i) => {
    const newFormRows = [...orderDetails];

    const newExtrafield = [...newFormRows[index].extra_field];
    newExtrafield.splice(i, 1); // Remove the object at index i
    newFormRows[index].extra_field = newExtrafield; // Update the orderDetails with the modified extraFields

    setOrderDetails(newFormRows);
  };

  const handleChangeExtraField = (e, index, idx) => {
    const { value } = e.target;
    const newFormRows = [...orderDetails];
    const newExtraFields = [...newFormRows[index].extra_field];

    newExtraFields[idx].name = value;
    setOrderDetails(newFormRows);
  };

  const getClientUsers = (searchKey) =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getuserList', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          role: 3,
          pageVo: {
            pageNo: 1,
            noOfItems: 100,
          },
          searchKey: searchKey,
          include: ['id', 'first_name', 'last_name', 'company_name'],
        }),
      })
        .then((response) =>
          response.json().then((data) => {
            resolve(data.data.rows);
          }),
        )
        .catch((err) => {
          reject(err);
        });
    });
  const getJobRoles = (searchKey) =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/jobrolelist', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          pageVo: {
            pageNo: 1,
            noOfItems: 100,
          },
          searchKey: searchKey,
          include: ['id', 'name'],
        }),
      })
        .then((response) =>
          response.json().then((data) => {
            resolve(
              data.data.rows.map(({ id, name }) => {
                return {
                  value: id,
                  label: name,
                };
              }),
            );
          }),
        )
        .catch((err) => {
          reject(err);
        });
    });

  const getUsers = (searchKey) =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getuserList', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          pageVo: {
            pageNo: 1,
            noOfItems: 100,
          },
          role: 2,

          searchKey: searchKey,
          include: ['id', 'first_name'],
        }),
      })
        .then((response) =>
          response.json().then((data) => {
            resolve(
              data.data.rows.map(({ id, first_name }) => {
                return {
                  value: id,
                  label: first_name,
                };
              }),
            );
          }),
        )
        .catch((err) => {
          reject(err);
        });
    });

  const handleChangeJobRole = (selectedoption, index) => {
    const newFormRows = [...orderDetails];
    newFormRows[index].title_id = selectedoption.value;
    newFormRows[index].job_title = selectedoption.label;
    setOrderDetails(newFormRows);
  };

  const handleChangeClientUser = (selectedValue) => {
    setOrder((prev) => ({
      ...prev,
      company_id: selectedValue.company_id,
      company_user_id: selectedValue.id,
    }));

    setSelectedClientUser(selectedValue);
  };

  const validateOrderDetails = () => {
    return orderDetails.every((detail) => {
      return (
        detail.title_id &&
        detail.noofjobs &&
        detail.description &&
        detail.mobilization &&
        detail.jd &&
        detail.extra_field.every((field) => {
          return field.name !== '';
        })
      );
    });
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    const { company_id, company_user_id, project_name } = order;
    // const [{]=orderDetails;
    if (!company_id || !company_user_id || !project_name || !validateOrderDetails()) {
      toast.warn('Please Complete The Form!', toastConstant.TOAST_PAYLOAD);
      return;
    }

    const orderQuery = {
      ...order,
      orderDetails,
    };

    try {
      const response = await orderService.addOrder(orderQuery);

      if (response) {
        navigate('/orders');
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      console.log('inside finally');
    }
  };

  const handleChangeOwner = (selectedValue) => {
    setOrder((prev) => ({
      ...prev,
      user_id: selectedValue.value,
    }));
    setAssignedUser(selectedValue.label);
  };

  return (
    <div className='useradd'>
      <div className=' userlistcard'>
        <div className='card-header'>
          <div className='d-flex flex-wrap justify-content-between'>
            <div className='userlisthead'>
              {' '}
              <ListPlus />
              Add Order
            </div>
          </div>
        </div>
        <form name='form'>
          <div className='card-body'>
            <div className='col-md-6 mt-3'>
              <div className={'form-group' + (submitted && !order.company_id ? ' has-error' : '')}>
                <AsyncSelect
                  cacheOptions
                  loadOptions={getClientUsers}
                  onChange={handleChangeClientUser}
                  defaultOptions
                  value={selectedClientUser} // Updated line
                  getOptionLabel={(user) => user?.first_name}
                  getOptionValue={(user) => user?.company_id}
                  className='async-select-form-control '
                  formatOptionLabel={(data, metaData) => {
                    if (metaData.context === 'menu') {
                      return (
                        <div className=''>
                          <div className='optiontitle'>{data?.first_name}</div>
                          <div className='optionsubtitle'>Company: {data?.Company}</div>
                        </div>
                      );
                    }
                    if (metaData?.context == 'value') {
                      return (
                        <div className=''>
                          <div className='optiontitle'>{data?.first_name}</div>
                        </div>
                      );
                    }
                  }}
                />
                {!selectedClientUser?.id && (
                  <span className='floating-label' style={{ fontSize: '11px' }}>
                    Client User Name
                  </span>
                )}
              </div>
              <div
                className={'form-group' + (submitted && !order.project_name ? ' has-error' : '')}
              >
                <input
                  onChange={handleChangeOrder}
                  type='text'
                  name='project_name'
                  value={order.project_name}
                  className='form-control mb-3'
                />
                <span className='floating-label'>Project Name</span>{' '}
              </div>
              <div className={'form-group' + (submitted && !order.company_id ? ' has-error' : '')}>
                <AsyncSelect
                  cacheOptions
                  loadOptions={getUsers}
                  onChange={handleChangeOwner}
                  defaultOptions
                  value={{ label: assignedUser }}
                  className='async-select-form-control '
                  placeholder='Select Client'
                />
                {!assignedUser && (
                  <span className='floating-label' style={{ fontSize: '11px' }}>
                    Select User
                  </span>
                )}
              </div>
            </div>
            <p className='ng-binding mt-5'>Job</p>
            <div className='card card-body pb-5  '>
              <table className='table'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Title</th>
                    <th>No of Jobs</th>
                    <th>Description</th>
                    <th>Mobilization Date</th>
                    <th>Extra Fields</th>
                    <th>Job Description</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails?.map((jobData, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div
                          className={
                            'form-group' + (submitted && !jobData.title_id ? ' has-error' : '')
                          }
                        >
                          <AsyncSelect
                            cacheOptions
                            loadOptions={getJobRoles}
                            onChange={(selectedoption) =>
                              handleChangeJobRole(selectedoption, index)
                            }
                            defaultOptions
                            value={{ label: jobData?.job_title }}
                            className='async-select-form-control mb-0 width-200'
                          />
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            'form-group' + (submitted && !jobData.noofjobs ? ' has-error' : '')
                          }
                        >
                          <input
                            onChange={(e) => handleChange(index, e)}
                            type='number'
                            name='noofjobs'
                            value={jobData.noofjobs}
                            className='form-control '
                          />
                          <span className='floating-label'>Enter No of Jobs</span>{' '}
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            'form-group' + (submitted && !jobData.description ? ' has-error' : '')
                          }
                        >
                          <input
                            onChange={(e) => handleChange(index, e)}
                            type='text'
                            name='description'
                            value={jobData.description}
                            className='form-control '
                          />
                          <span className='floating-label'>description</span>{' '}
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            'form-group' + (submitted && !jobData.mobilization ? ' has-error' : '')
                          }
                        >
                          <input
                            onChange={(e) => handleChange(index, e)}
                            type='date'
                            name='mobilization'
                            value={moment(jobData?.mobilization).format('YYYY-MM-DD')}
                            className='form-control '
                            min={moment().format('YYYY-MM-DD')}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='extrafieldscontainer '>
                          {jobData?.extra_field.map((extrafield, i) => (
                            <div
                              key={i}
                              className={
                                'position-relative ' +
                                (submitted && !extrafield?.name ? ' has-error' : '')
                              }
                            >
                              <input
                                onChange={(e) => handleChangeExtraField(e, index, i)}
                                type='text'
                                name='extrafield'
                                value={extrafield?.name}
                                className='form-control '
                                placeholder='Field Name'
                              />
                              <span
                                className='closeextrafield'
                                onClick={() => removeExtraField(index, i)}
                              >
                                <CircleX size={15} />
                              </span>
                            </div>
                          ))}
                        </div>

                        <div
                          className='extra-fields-btn mt-1'
                          onClick={() => handleAddExtraField(index)}
                        >
                          <CirclePlus />
                        </div>
                      </td>
                      <td>
                        <input
                          type='file'
                          onChange={(e) => {
                            handleFileUpload(index, e);
                          }}
                          id={'jdinput' + index}
                          style={{ display: 'none' }}
                          accept='.pdf'
                        />
                        {jobData?.jd ? (
                          <label className='uploadedjd'>
                            <CircleCheck /> JD
                            <span
                              className='removejd'
                              onClick={() => {
                                handleRemoveJd(index);
                              }}
                            >
                              (X)
                            </span>
                          </label>
                        ) : (
                          <label
                            htmlFor={'jdinput' + index}
                            className={'uploadjd ' + (submitted && !jobData?.jd ? 'showerror' : '')}
                          >
                            {' '}
                            {isJdUploading.status && isJdUploading.index == index && (
                              <Loader size={15} className='mb-1' />
                            )}
                            {!isJdUploading.status && isJdUploading.index != index && (
                              <Upload size={15} className='mb-1' />
                            )}
                            {isJdUploading.status && isJdUploading.index == index
                              ? ' Uploading'
                              : ' Upload'}
                          </label>
                        )}
                      </td>
                      <td>
                        <div onClick={() => handleRemoveRow(index)}>
                          {' '}
                          <CircleX />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='addrowbtn' onClick={handleAddRow}>
                <CopyPlus />
              </div>
            </div>
            <div
              className='savebtn mt-4 mb-4'
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
