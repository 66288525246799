import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastConstant } from '../_constants/toast.constants';
import { jobService } from '../_services/job.service';

export const JobRoleAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [jobRoleData, setJobRoleData] = useState({
    name: '',
  });
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (id) {
      getJobRoleById(id);
    }
  }, [id]);

  const getJobRoleById = async (id) => {
    const query = {
      id: id,
    };
    try {
      const response = await jobService.getJobyRoleById(query);

      if (response.data) {
        setJobRoleData(response.data);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      console.log('finally');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobRoleData({
      ...jobRoleData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    const { name } = jobRoleData;
    if (!name) {
      toast.error('Add Job Title!', toastConstant.TOAST_PAYLOAD);
      return;
    }
    setSubmitted(false);

    try {
      const response = await jobService.addJobRole(jobRoleData);
      if (response?.data) {
        navigate('/jobroles');
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      console.log('company add finally');
    }
  };

  const handleStatusChange = (status) => {
    setJobRoleData((prev) => ({
      ...prev,
      status: status,
    }));
  };

  return (
    <div className='useradd'>
      <div className=' userlistcard'>
        <div className='card-header '>
          <div className='d-sm-flex align-items-center justify-content-between'>
            <div className='userlisthead'>Add Job Title</div>
          </div>
        </div>
        <form name='form' onSubmit={handleSubmit}>
          <div className='card-body' style={{ minHeight: '30vh' }}>
            <div className='row mt-5'>
              <div className='col-md-4 '>
                <div
                  className={'form-group' + (submitted && !jobRoleData.name ? ' has-error' : '')}
                >
                  <input
                    onChange={handleChange}
                    type='text'
                    name='name'
                    value={jobRoleData.name}
                    className='form-control mb-3'
                  />
                  <span className='floating-label'>Job Title</span>{' '}
                </div>
              </div>

              <div className='row'>
                {id == 0 ? (
                  ''
                ) : (
                  <div className='col-sm-12 mb-3'>
                    <div className='btn-group form-group'>
                      <button
                        className={`btn btn-primary btn-sm ${
                          jobRoleData?.status === 1 ? 'active' : ''
                        }`}
                        onClick={() => handleStatusChange(1)}
                        type='button'
                      >
                        Active
                      </button>
                      <button
                        className={`btn btn-primary btn-sm ${
                          jobRoleData?.status === 0 ? 'active' : ''
                        }`}
                        onClick={() => handleStatusChange(0)}
                        type='button'
                      >
                        Inactive
                      </button>
                    </div>
                  </div>
                )}
                <div className='col-sm-12'>
                  <button className='btn btn-primary btn-sm'>
                    {id == 0 ? 'Save' : 'Save changes'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
