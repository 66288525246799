import { CircleCheck, CircleX, CopyPlus, Loader, Upload } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastConstant } from '../_constants/toast.constants';
import { candidateService } from '../_services/candidate.service';
import { miscService, UploadS3UsingFetch } from '../_services/misc.service';
import { orderService } from '../_services/order.service';

export const CandidateAdd = () => {
  const { id, orderdetails_id } = useParams();
  const navigate = useNavigate();
  const [submitted] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [isJdUploading, setIsJdUploading] = useState({ status: false, index: null });
  const [candidateList, setCandidateList] = useState([
    {
      name: '',
      email: '',
      phone: '',
      cvurl: '',
      extra_field_value: [],
      job_description: '',
      available: null,
    },
  ]);

  useEffect(() => {
    if (orderdetails_id) {
      getOrderDetailsById(orderdetails_id);
    }
  }, [orderdetails_id]);

  const getOrderDetailsById = async (id) => {
    try {
      const query = {
        id: id,
      };

      const res = await orderService.getOrderDetailsById(query);

      if (res) {
        setOrderData(res.data);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      console.log('inside order finally');
    }
  };

  const handleAddRow = () => {
    setCandidateList([
      ...candidateList,
      {
        name: '',
        email: '',
        phone: '',
        cvurl: '',
        extra_field_value: [],
        job_description: '',
        available: null,
      },
    ]);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newFormRows = [...candidateList];

    if (name == 'available') {
      newFormRows[index][name] = moment(value).valueOf();
    } else {
      newFormRows[index][name] = value;
    }
    setCandidateList(newFormRows);
  };

  // Handler to remove a row
  const handleRemoveRow = (index) => {
    // const newFormRows = [...orderData];
    // newFormRows.splice(index, 1);
    // setOrderData(newFormRows);
    const newFormRows = [...candidateList];
    newFormRows.splice(index, 1);
    setCandidateList(newFormRows);
  };

  const handleChangeExtraField = (index, extraidx, e) => {
    const { name, value } = e.target;
    const newFormRows = [...candidateList];
    const newExtraFieldValue = [...newFormRows[index].extra_field_value]; // Corrected variable name
    newExtraFieldValue[extraidx] = { ...newExtraFieldValue[extraidx], [name]: value }; // Fixed update logic
    newFormRows[index].extra_field_value = newExtraFieldValue; // Correctly assign back to the candidate
    setCandidateList(newFormRows);
  };

  const handleFileUpload = async (index, event) => {
    setIsJdUploading({ status: true, index: index });
    const file = event?.target?.files[0];
    try {
      const ext = file.name.split('.').pop();
      const response = await miscService.createSignedUploadUrl({
        type: 'file',
        mime_type: file.type,
        ext,
      });

      const { signedUrl, filename } = response.data;
      const uploadResponse = await UploadS3UsingFetch(file, signedUrl);
      if (uploadResponse) {
        const newFormRows = [...candidateList];
        newFormRows[index].cvurl = filename;
        setCandidateList(newFormRows);
      } else {
        toast.error('Upload Failed! Try again!', toastConstant.TOAST_PAYLOAD);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsJdUploading({ status: false, index: null });
    }
  };

  const handleRemoveCv = (index) => {
    const newFormRows = [...candidateList];

    newFormRows[index].cvurl = null;

    setCandidateList(newFormRows);
  };

  const handleSubmit = async () => {
    try {
      const candidateQuery = candidateList.map((candidate) => ({
        ...candidate,
        company_id: orderData?.company_id,
        jobtitleid: orderData?.title_id,
        company_userid: orderData?.company_user_id,
        orderdetails_id: orderData?.id,
        order_id: orderData?.order_id,
      }));

      const response = await candidateService.candidateBulkAdd(candidateQuery);
      if (response) {
        navigate('/ordersingle/' + orderData?.id + '/' + orderData?.order_id);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      console.log('inside finally statement');
    }
  };
  return (
    <div className='useradd'>
      <div className=' userlistcard'>
        <div className='card-header '>
          <div className='d-sm-flex align-items-center justify-content-between'>
            <div className='userlisthead'>Add Candidate</div>
          </div>
        </div>
        <div className='orderdetails justify-content-between'>
          <div className='detailitem'>
            <span className='ng-binding'>Order # </span>
            <b className='ng-binding'>{orderData?.order_details_index}</b>
          </div>
          <div className='detailitem'>
            <span className='ng-binding'>Client User </span>
            <b className='ng-binding'>{orderData?.client_user}</b>
          </div>
          <div className='detailitem'>
            <span className='ng-binding'>Company </span>
            <b className='ng-binding'>{orderData?.company}</b>
          </div>
          <div className='detailitem'>
            <span className='ng-binding'>Job Title </span>
            <b className='ng-binding'>{orderData?.job_title}</b>
          </div>
          <div className='detailitem' style={{ flexDirection: 'column' }}>
            <b className='ng-binding'>{moment(orderData?.createdAt).format('MMM D, YYYY')}</b>
            <span className='ng-binding'>{moment(orderData?.createdAt).format(' h:mm A')}</span>
          </div>
        </div>
        <div className='card card-body pb-5  table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Remarks</th>
                <th>Availability</th>
                {orderData?.extra_field?.map(({ name, index }) => {
                  return <th key={index}>{name}</th>;
                })}
                <th>Curriculum Vitate</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {candidateList?.map((candidate, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td className='min-100'>
                    <div
                      className={'form-group' + (submitted && !candidate.name ? ' has-error' : '')}
                    >
                      <input
                        onChange={(e) => handleChange(index, e)}
                        type='text'
                        name='name'
                        value={candidate?.name}
                        className='form-control '
                      />
                      <span className='floating-label'>Name</span>{' '}
                    </div>
                  </td>
                  <td className='min-100'>
                    <div
                      className={'form-group' + (submitted && !candidate.email ? ' has-error' : '')}
                    >
                      <input
                        onChange={(e) => handleChange(index, e)}
                        type='email'
                        name='email'
                        value={candidate.email}
                        className='form-control '
                      />
                      <span className='floating-label'>Email</span>{' '}
                    </div>
                  </td>
                  <td className='min-100'>
                    <div
                      className={'form-group' + (submitted && !candidate.phone ? ' has-error' : '')}
                    >
                      <input
                        onChange={(e) => handleChange(index, e)}
                        type='number'
                        name='phone'
                        value={candidate.phone}
                        className='form-control '
                      />
                      <span className='floating-label'>Phone</span>{' '}
                    </div>
                  </td>
                  <td className='min-100'>
                    <div
                      className={
                        'form-group' + (submitted && !candidate.job_description ? ' has-error' : '')
                      }
                    >
                      <input
                        onChange={(e) => handleChange(index, e)}
                        type='text'
                        name='job_description'
                        value={candidate?.job_description}
                        className='form-control '
                      />
                    </div>
                  </td>
                  <td className='min-100'>
                    <div
                      className={
                        'form-group' + (submitted && !candidate.available ? ' has-error' : '')
                      }
                    >
                      <input
                        onChange={(e) => handleChange(index, e)}
                        type='date'
                        name='available'
                        value={moment(candidate?.available).format('YYYY-MM-DD')}
                        className='form-control '
                        min={moment().format('YYYY-MM-DD')}
                      />
                    </div>
                  </td>

                  {orderData?.extra_field?.map((extrafield, i) => (
                    <td key={i}>
                      <div className='extrafieldscontainer'>
                        <div className='position-relative'>
                          <input
                            onChange={(e) => handleChangeExtraField(index, i, e)}
                            type='text'
                            name={extrafield.name}
                            value={candidate?.extra_field_value[extrafield.name]}
                            className='form-control '
                            placeholder={extrafield.name}
                          />
                        </div>
                      </div>
                    </td>
                  ))}

                  <td>
                    <input
                      type='file'
                      onChange={(e) => {
                        handleFileUpload(index, e);
                      }}
                      id={'cvinput' + index}
                      style={{ display: 'none' }}
                      accept='.pdf'
                    />
                    {candidate?.cvurl ? (
                      <label className='uploadedjd'>
                        <CircleCheck /> JD
                        <span
                          className='removejd'
                          onClick={() => {
                            handleRemoveCv(index);
                          }}
                        >
                          (X)
                        </span>
                      </label>
                    ) : (
                      <label
                        htmlFor={'cvinput' + index}
                        className={
                          'uploadjd ' + (submitted && !candidate?.cvurl ? 'showerror' : '')
                        }
                      >
                        {' '}
                        {isJdUploading.status && isJdUploading.index == index && (
                          <Loader size={15} className='mb-1' />
                        )}
                        {!isJdUploading.status && isJdUploading.index != index && (
                          <Upload size={15} className='mb-1' />
                        )}
                        {isJdUploading.status && isJdUploading.index == index
                          ? ' Uploading'
                          : ' Upload'}
                      </label>
                    )}
                  </td>
                  <td>
                    <div onClick={() => handleRemoveRow(index)}>
                      {' '}
                      <CircleX />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='addrowbtn' onClick={handleAddRow}>
            <CopyPlus />
          </div>

          <div className='col-sm-12'>
            <button className='btn btn-primary btn-sm' onClick={() => handleSubmit()}>
              {id == 0 ? 'Save' : 'Save changes'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
